import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Pie } from 'react-chartjs-2';
import { getTasksWithTypes, getTasksWithTypesByDateRange } from '../services/api';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title);

const TaskAnalytics = ({ token }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tasks, setTasks] = useState([]);

  const fetchTasks = useCallback(async (start, end) => {
    try {
      let data;
      if (start && end) {
        data = await getTasksWithTypesByDateRange(start, end, token);
      } else {
        data = await getTasksWithTypes(token);
      }
      setTasks(data);
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchTasks(null, null);
  }, [fetchTasks]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    fetchTasks(start, end);
  };

  const taskData = tasks.reduce((acc, task) => {
    const typeName = task.type_name || 'Unknown';
    const durationHours = task.duration_hours + task.duration_minutes / 60 + task.duration_seconds / 3600;
    acc[typeName] = (acc[typeName] || 0) + durationHours;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(taskData),
    datasets: [
      {
        data: Object.values(taskData),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 border rounded">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Select Period:
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            className="mt-1 p-2 border rounded w-full"
            placeholderText="Select a date range"
          />
        </label>
      </div>
      <div>
        <Pie data={chartData} />
      </div>
    </div>
  );
};

export default TaskAnalytics;
