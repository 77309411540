import React from 'react';
import TaskAnalytics from '../components/TaskAnalytics';
import Navbar from '../components/Navbar';

const TaskAnalyticsPage = ({ token }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-blue-500 text-white p-4">
        <h1 className="text-xl font-bold">Task Analytics</h1>
      </header>
      <Navbar />
      <main className="flex-grow p-4">
        <TaskAnalytics token={token} />
      </main>
      <footer className="bg-gray-800 text-white p-4 mt-4 text-center">
        <p>© 2024 My Personal Page</p>
      </footer>
    </div>
  );
};

export default TaskAnalyticsPage;
