import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="bg-gray-800 p-4">
      <div className="max-w-7xl mx-auto flex justify-between">
        <div className="text-white text-lg">
          <Link to="/" className="mr-4">Home</Link>
          <Link to="/add-task" className="mr-4">Add Task</Link>
          <Link to="/task-history" className="mr-4">Task History</Link>
          <Link to="/task-analytics" className="mr-4">Task Analytics</Link>
        </div>
        <div>
          <Link to="/login" className="text-blue-400">Login</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
