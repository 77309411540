import React, { useState, useEffect } from 'react';
import { getTaskTypes, createTask } from '../services/api';
import { useStopwatch } from 'react-timer-hook';

const AddTaskForm = ({ token }) => {
  const [name, setName] = useState('');
  const [typeId, setTypeId] = useState('');
  const [taskTypes, setTaskTypes] = useState([]);
  const [duration, setDuration] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [useTimer, setUseTimer] = useState(false);
  const {
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  useEffect(() => {
    const fetchTaskTypes = async () => {
      const data = await getTaskTypes(token);
      setTaskTypes(data);
    };

    fetchTaskTypes();
  }, [token]);

  const handleAddTask = async () => {
    const task = {
      name,
      type_id: parseInt(typeId),
      duration_hours: useTimer ? hours : duration.hours,
      duration_minutes: useTimer ? minutes : duration.minutes,
      duration_seconds: useTimer ? seconds : duration.seconds,
    };

    try {
      await createTask(task, token);
      // Обнуление полей
      setName('');
      setTypeId('');
      setDuration({ hours: 0, minutes: 0, seconds: 0 });
      reset();
    } catch (error) {
      console.error('Failed to create task:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-4 border rounded">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Task Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 p-2 border rounded w-full"
          />
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Task Type:
          <select
            value={typeId}
            onChange={(e) => setTypeId(e.target.value)}
            className="mt-1 p-2 border rounded w-full"
          >
            <option value="">Select Type</option>
            {taskTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Use Timer:
          <input
            type="checkbox"
            checked={useTimer}
            onChange={(e) => setUseTimer(e.target.checked)}
            className="mt-1 ml-2"
          />
        </label>
      </div>
      {useTimer ? (
        <div className="mb-4">
          <div className="block text-gray-700 text-sm font-bold mb-2">
            Timer: {hours}:{minutes}:{seconds}
          </div>
          <button onClick={start} className="bg-green-500 text-white p-2 rounded mr-2">
            Start
          </button>
          <button onClick={pause} className="bg-yellow-500 text-white p-2 rounded mr-2">
            Pause
          </button>
          <button onClick={reset} className="bg-red-500 text-white p-2 rounded">
            Reset
          </button>
        </div>
      ) : (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Duration Hours:
            <input
              type="number"
              value={duration.hours}
              onChange={(e) => setDuration({ ...duration, hours: e.target.value })}
              className="mt-1 p-2 border rounded w-full"
            />
          </label>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Duration Minutes:
            <input
              type="number"
              value={duration.minutes}
              onChange={(e) => setDuration({ ...duration, minutes: e.target.value })}
              className="mt-1 p-2 border rounded w-full"
            />
          </label>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Duration Seconds:
            <input
              type="number"
              value={duration.seconds}
              onChange={(e) => setDuration({ ...duration, seconds: e.target.value })}
              className="mt-1 p-2 border rounded w-full"
            />
          </label>
        </div>
      )}
      <div className="text-center">
        <button onClick={handleAddTask} className="bg-blue-500 text-white p-2 rounded">
          Add Task
        </button>
      </div>
    </div>
  );
};

export default AddTaskForm;
