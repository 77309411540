import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import AddTaskPage from './pages/AddTaskPage';
import TaskHistoryPage from './pages/TaskHistoryPage';
import TaskAnalyticsPage from './pages/TaskAnalyticsPage';
import { verifyToken } from './services/api';
import NotFoundPage from "./pages/NotFoundPage";

const App = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      verifyToken(storedToken).then((isValid) => {
        if (isValid) {
          setToken(storedToken);
        } else {
          localStorage.removeItem('token');
        }
      });
    }
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setToken(token);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/login"
          element={token ? <Navigate to="/add-task" /> : <LoginPage setToken={handleLogin} />}
        />
        {token && (
          <>
            <Route path="/add-task" element={<AddTaskPage token={token} />} />
            <Route path="/task-history" element={<TaskHistoryPage token={token} />} />
            <Route path="/task-analytics" element={<TaskAnalyticsPage token={token} />} />
          </>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
