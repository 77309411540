import React from 'react';
import { Link } from 'react-router-dom';
import buildindgLogo from '../assets/building.svg';
import myAvatar from '../assets/me.png';

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-neutral-900 text-white font-sans">
      <main className="flex-grow content-center">
        <div className="flex flex-col items-center mt-10 gap-4">
          <img
            src={myAvatar}
            alt="Me"
            className="rounded-full w-40 h-40 mb-4"
          />
          <h1 className="text-3xl font-bold">Мацнев Григорий</h1>
          <p className="text-xl">AI Developer & AI Consultant</p>
          <div className="mt-4 flex gap-2 items-center">
            <img src={buildindgLogo} className="w-6 h-auto" alt="buildindgLogo"/>
            <Link to="https://allsee.team" className="text-white font-semibobold text-2xl hover:text-blue-400">AllSee</Link>
          </div>
        </div>
      </main>
      <footer className="relative bg-neutral-900 p-4 text-center mt-10">
        <div
            className="absolute bottom-0 left-0 right-0 p-2 bg-neutral-900 transition-opacity duration-300 ease-in-out opacity-0 hover:opacity-100">
          <Link to="/login" className="text-blue-400 hover:underline">Admin</Link>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
