import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';

const LoginPage = ({ setToken }) => {
  const navigate = useNavigate();

  const handleLogin = async (token) => {
    setToken(token);
    navigate('/add-task');
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-blue-500 text-white p-4">
        <h1 className="text-xl font-bold">Login to Your Account</h1>
      </header>
      <main className="flex-grow p-4">
        <LoginForm onLogin={handleLogin} />
      </main>
      <footer className="bg-gray-800 text-white p-4 mt-4 text-center">
        <p>© 2024 My Personal Page</p>
      </footer>
    </div>
  );
};

export default LoginPage;
