import axios from 'axios';

const API_BASE_URL = "https://matsnev.pro/api";

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const getToken = async (username, password) => {
  const response = await api.post('/token', new URLSearchParams({ username, password }));
  return response.data;
};

export const verifyToken = async (token) => {
  try {
    const response = await api.post('/token/verify/', { token });
    return response.data.status === "valid";
  } catch (error) {
    return false;
  }
};

export const getTasks = async (token) => {
  const response = await api.get('/tasks/', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getTasksWithTypes = async (token) => {
  const response = await api.get('/tasks/with-types/', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getTasksWithTypesByDateRange = async (startDate, endDate, token) => {
  const params = {};
  if (startDate) params.start_date = startDate.toISOString();
  if (endDate) params.end_date = endDate.toISOString();

  const response = await api.get('/tasks/with-types/by-date/', {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
  return response.data;
};

export const getTasksByDateRange = async (startDate, endDate, token) => {
  const params = {};
  if (startDate) params.start_date = startDate.toISOString();
  if (endDate) params.end_date = endDate.toISOString();

  const response = await api.get('/tasks/by-date/', {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
  return response.data;
};

export const createTask = async (task, token) => {
  const response = await api.post('/tasks/', task, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const deleteTask = async (taskId, token) => {
  const response = await api.delete(`/tasks/${taskId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getTaskTypes = async (token) => {
  const response = await api.get('/task_types/', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const createTaskType = async (taskType, token) => {
  const response = await api.post('/task_types/', taskType, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const deleteTaskType = async (taskTypeId, token) => {
  const response = await api.delete(`/task_types/${taskTypeId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
