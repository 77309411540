import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getTasksWithTypes, getTasksWithTypesByDateRange, deleteTask } from '../services/api';

const TaskHistory = ({ token }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const fetchTasks = useCallback(async (start, end) => {
    try {
      let data;
      if (start && end) {
        data = await getTasksWithTypesByDateRange(start, end, token);
      } else {
        data = await getTasksWithTypes(token);
      }
      setTasks(data);
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchTasks(null, null);
  }, [fetchTasks]);

  const handleDelete = async (taskId) => {
    try {
      await deleteTask(taskId, token);
      fetchTasks(startDate, endDate);
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    fetchTasks(start, end);
  };

  const filteredTasks = tasks.filter(task => {
    return (
      (!selectedType || task.type_id === parseInt(selectedType)) &&
      task.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 border rounded">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Select Period:
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            className="mt-1 p-2 border rounded w-full"
            placeholderText="Select a date range"
          />
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Filter by Task Type:
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className="mt-1 p-2 border rounded w-full"
          >
            <option value="">All Types</option>
            {tasks.map((task) => (
              <option key={task.type_id} value={task.type_id}>
                {task.type_name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Search by Task Name:
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mt-1 p-2 border rounded w-full"
          />
        </label>
      </div>
      <ul>
        {filteredTasks.map((task) => (
          <li key={task.id} className="mb-2 p-2 border rounded flex justify-between items-center">
            <div>
              <div className="font-bold">{task.name}</div>
              <div className="text-sm text-gray-600">{task.type_name}</div>
              <div className="text-sm text-gray-600">
                {task.duration_hours}h {task.duration_minutes}m {task.duration_seconds}s
              </div>
              <div className="text-sm text-gray-600">{new Date(task.time_added).toLocaleString()}</div>
            </div>
            <button
              onClick={() => handleDelete(task.id)}
              className="text-red-500 hover:text-red-700 ml-4 p-2"
            >
              ❌
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskHistory;
